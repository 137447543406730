import { lookupDayCount, lookupFloorType, lookupRFRvalue, RFRvalues } from "../data/AllInputOptions";
import { isBalanceTransferHidden, isInterestReceivedHidden, isLockoutDisabled, isLookbackDisabled, isNoSpread, isObservationShiftDisabled, isTenorEnabled, isTermRateEnabled } from "../data/InputAvailability";
import { Index, InputModel, ResponseView, ResultsModel, ValidationInputs } from "../models/InputModel";
import { UIBusinessDayCountRequest, UIDateRangeRequest, UIHolidayDatesRequest, UIRequest, UIRequestRange } from "../models/UIRequestModel";
import { getApiUrl } from "./APIService";
import CryptoES from 'crypto-es';
import { APIBusinessDayCountResponse, APIDateRangeResponse, APIHolidayDatesResponse, APIReponseBase, APIResponse, CalcResponse, ErrorResponse, isErrorResponse } from "../models/ResponseModel";
import { utc } from "moment";
import { displayLoading, hideLoading } from "./UnionIntegration";
import { dataDateFormat } from "../data/DateUtils";

function createRequest(inputs: InputModel): UIRequest {
  const floorType = inputs.floorType == null ? null : lookupFloorType(inputs.floorType);
  return {
    Ranges:
      inputs.commitment.map<UIRequestRange>((r, i, c) =>
      ({
        StartDate: r.effectiveDate,
        EndDate: (i < c.length - 1 ? c[i + 1].effectiveDate : inputs.endDate),
        Notional: r.notional,
        Spread: r.spread,
        ...(r.change != 0 && !isBalanceTransferHidden(r, inputs) && r.balanceTransfer ? { BalanceTransfer: true } : {}),
        ...(r.change != 0 && !isInterestReceivedHidden(r) && r.interestReceived ? { InterestReceived: true } : {})
      })),
    PaymentHolidays: inputs.selectedCountryForHolidays,
    DayCount: inputs.dayCount,
    Frequency: lookupDayCount(inputs.dayCount).v2,
    RFR: inputs.RFR,
    InterestMethod: inputs.interestMethod,
    Rounding: inputs.rateRounding,
    Floor: inputs.floor,
    TermRate: isTermRateEnabled(inputs) ? inputs.termRate : null,
    //Tenor: isTenorEnabled(inputs) ? inputs.tenor.value : null,
    IncludeAdjustment:
      (inputs.floor == null || inputs.adjustment == 0 || inputs.adjustment == null)
        ? null
        : floorType?.inclAdj ?? null,
    AllInRateFloor:
      (inputs.floor == null || isNoSpread(inputs))
        ? null
        : floorType?.allInRate ?? null,
    Adjustment: inputs.adjustment,
    Lookback: isLookbackDisabled(inputs) ? 0 : Number(inputs.lookBack),
    Lockout: isLockoutDisabled(inputs) ? 0 : Number(inputs.lockout),
    ObservationShift: inputs.observationShift && !isObservationShiftDisabled(inputs),
    //Debug: false
  };
}

const isLocalHost = (window.location.hostname == "localhost");
export const isMocked = (window.location.host == "localhost:4200");

const jsonHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json, text/plain, */*'
};
function getHeaders(): HeadersInit {
  if (isLocalHost) {
    return jsonHeaders;
  }
  const token =
    CryptoES.AES.decrypt(
      sessionStorage["union-access-token"],
      "union-access-token").toString(CryptoES.enc.Utf8).replace(/['"]+/g, '');
  return {
    ...jsonHeaders,
    'Authorization': 'Bearer ' + token,
  };
}

export async function doCalculate(inputs: InputModel, setResults: React.Dispatch<React.SetStateAction<ResultsModel | null>>, setApiErrorMessage: (message: string | null) => void, controller: AbortController) {
  if (isMocked) return;
  const request = createRequest(inputs);
  displayLoading()
  const response =
    await fetch(getApiUrl() + '/rfr', {
      signal: controller.signal,
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(request)
    });
  hideLoading()
  if (!response.ok) {
    handleAuthenticationError(response);
  }
  const result = await response.json() as (APIResponse | ErrorResponse);
  if (isErrorResponse(result)) {
    setApiErrorMessage(result.Error);
  } else {
    setResults({
      inputs,
      response: result.CalcResponse
    });
  }
}

function handleAuthenticationError(error: Response) {
  if (error.status == 401) {
    const message = error.headers.get("Message");
    if (message === "reload") {
      window.parent.location.reload();
    } else if (message == "unauthorise") {
      const eventHub = (window as any).eventHub;
      if (eventHub) {
        eventHub.emit('unauthorise', "Risk Free Rates");
      }
    }
  }
}

const apiVersionExpected = 10;
let versionAlertGiven = false;

function checkApiVersion(result: APIReponseBase) {
  let apiVersion = result.ApiVersion;
  if (!apiVersion) apiVersion = 0;
  if (apiVersion > apiVersionExpected) {
    if (!versionAlertGiven) {
      alert("There has been an update to Risk Free Rates calculator, please refresh your page.");
      versionAlertGiven = true;
    }
  }
  if (apiVersion < apiVersionExpected) {
    console.error(`Deployment issue: UI expects a higher API version. API has version ${apiVersion} while UI expects version ${apiVersionExpected}.`);
  }
}

export async function updateBusinessDayCount(inputs: InputModel, setValidationInputs: React.Dispatch<React.SetStateAction<ValidationInputs>>, setApiErrorMessage: (message: string | null) => void, controller: AbortController) {
  if (isMocked) return;
  if (inputs.interestMethod === "Term" || inputs.interestMethod === "TermCME") {
    return;
  }
  const request: UIBusinessDayCountRequest =
  {
    StartDate: inputs.commitment[0].effectiveDate,
    EndDate: inputs.endDate,
    PaymentHolidays: inputs.selectedCountryForHolidays
  };
  const response =
    await fetch(getApiUrl() + '/holidays/days', {
      signal: controller.signal,
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(request)
    });
  if (!response.ok) {
    handleAuthenticationError(response);
  }
  const result = await response.json() as (APIBusinessDayCountResponse | ErrorResponse);
  checkApiVersion(result);
  if (isErrorResponse(result)) {
    setApiErrorMessage(result.Error);
  } else {
    setApiErrorMessage(null);
    const businessDayCount = result.BusinessDayCount;
    setValidationInputs(vi => ({ ...vi, businessDayCount }));
  }
}

export async function updateHolidayDates(inputs: InputModel, setValidationInputs: React.Dispatch<React.SetStateAction<ValidationInputs>>, setApiErrorMessage: (message: string | null) => void, controller: AbortController) {
  if (isMocked) return;
  const request: UIHolidayDatesRequest =
  {
    PaymentHolidays: inputs.selectedCountryForHolidays
  };
  const response =
    await fetch(getApiUrl() + '/holidays/dates', {
      signal: controller.signal,
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(request)
    });
  if (!response.ok) {
    handleAuthenticationError(response);
  }
  const result = await response.json() as (APIHolidayDatesResponse | ErrorResponse);
  checkApiVersion(result);
  if (isErrorResponse(result)) {
    setApiErrorMessage(result.Error);
  } else {
    setApiErrorMessage(null);
    const holidayDates = result.HolidayDates.map(d => utc(d, dataDateFormat));
    setValidationInputs(vi => ({ ...vi, holidayDates }));
  }
}

export async function getAvailableDateRange(inputs: InputModel, setValidationInputs: React.Dispatch<React.SetStateAction<ValidationInputs>>, setApiErrorMessage: (message: string | null) => void, controller: AbortController) {
  if (inputs.interestMethod === "Term") {
    const minStartDate = utc({ year: 1900, month: 0, date: 1 }); // "01/01/1900"
    setValidationInputs(vi => ({ ...vi, minStartDate }));
    return;
  }
  if (isMocked) return;
  const request: UIDateRangeRequest =
  {
    Lookback: isLookbackDisabled(inputs) ? 0 : inputs.lookBack,
    Lockout: isLockoutDisabled(inputs) ? 0 : inputs.lockout,
    PaymentHolidays: inputs.selectedCountryForHolidays,
    RFR: inputs.RFR,
    //Tenor: isTenorEnabled(inputs) ? inputs.tenor.value : null
  };
  const response =
    await fetch(getApiUrl() + '/rates/range', {
      signal: controller.signal,
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(request)
    });
  if (!response.ok) {
    handleAuthenticationError(response);
  }
  const result = await response.json() as (APIDateRangeResponse | ErrorResponse);
  checkApiVersion(result);
  if (isErrorResponse(result)) {
    setApiErrorMessage(result.Error);
  } else {
    setApiErrorMessage(null);
    const minStartDateWithRateData = utc(result.MinStartDate, dataDateFormat);
    const maxEndDateWithRateData = utc(result.MaxEndDate, dataDateFormat);
    const rfr = lookupRFRvalue(inputs.RFR);
    const mostRecentPublishedDate = utc(result.LastPublishedDate, dataDateFormat).format(rfr.dateFormat);
    setValidationInputs(vi => ({ ...vi, minStartDateWithRateData, maxEndDateWithRateData, mostRecentPublishedDate }))
  }
}
